import { PageProps } from "gatsby";
import CampaignChatbot from "../../../components/CampaignChatbot/LineOA";
import withPages from "../../../hoc/withPages";

function IndexPage(props: PageProps) {
  return withPages(CampaignChatbot)({
    title:
      "deeple ระบบจัดการร้านค้าออนไลน์ครบวงจรที่รู้ใจทุกธุรกิจ ใช้งานบน LINE OA",
    description:
      "ระบบจัดการร้านค้าออนไลน์ แชทบอทปิดการขาย จัดการออเดอร์ ดูดคอมเมนต์ไลฟ์ และช่วยขายครบวงจร สำหรับธุรกิจบน LINE OA, Facebook และ Instagram",
    ...props,
  });
}

export default IndexPage;
