import React from "react";
import "../../CardScroller/index.css";
import LottiePlayer from "../../LottiePlayer";

import homeStoreImage from "../../../../static/image/home-store.png";
import conversationImage from "../../../../static/image/conversation.png";
import platformConnectImage from "../../../../static/image/campaign/platform-connect.png";
import createStoreImage from "../../../../static/image/campaign/create-store.png";

type CardPropsType = {
  children?: React.ReactNode;
};

const CardScrollerFeature: React.FC<CardPropsType> = () => {
  const cardDetails = [
    {
      text1: "",
      text2: "ระบบหลังร้าน จัดการออเดอร์",
      text3: "สต็อกครบวงจร",
      videoLink: null,
      image: homeStoreImage,
    },
    {
      text1: "",
      text2: "แชทบอทตอบคำถาม เสนอขายสินค้า",
      text3: "ปิดการขาย 24 ช.ม.",
      videoLink: null,
      image: conversationImage,
      background: "bg-black",
      textColor: "text-white",
    },
    {
      text1: "",
      text2: "เชื่อมต่อและขายผ่าน LINE OA",
      videoLink: null,
      image: platformConnectImage,
    },
    {
      text1: "",
      text2: "ติดตั้งง่าย ไม่ต้องใช้คีย์เวิร์ด",
      videoLink: null,
      image: createStoreImage,
    },
  ];
  return (
    <div className="relative pt-8">
      <div className="card-scroller-container p-6">
        <div>
          <h3 className="text-white font-weight-600 gradient-text dashboard-heading-2-gradient pb-2 w-fit-content">
            deeple, Social Commerce One-Stop Service Platform
          </h3>
          <h2 className="cards-shelf-main-header dashboard-heading-2">
            ตอบโจทย์การขายออนไลน์&nbsp;
          </h2>
          <span className="cards-shelf-secondary-header dashboard-heading-2">
            อย่างมีประสิทธิภาพ
          </span>
        </div>
      </div>
      <div className="dp-cards-scroller dp-card-height-260">
        <div className="dp-cards-scroller-crop">
          <div className="dp-cards-scroller-content">
            <div className="dp-cards-scroller-platter">
              {cardDetails.map((card, index) => {
                return (
                  <div
                    className="dp-cards-scroller-item-view dp-card-height-220"
                    key={card.text1}
                  >
                    <div className="dp-card-width-360">
                      <div
                        className={`dp-card-content dp-card-height-220 ${
                          card?.background || "bg-white"
                        }`}
                      >
                        <div className="dp-card-img-full-wrapper mt-10">
                          <div
                            className={
                              [0, 1].includes(index) ? "mt-10" : "mt-4"
                            }
                          >
                            {card.videoLink && (
                              <LottiePlayer
                                key={card.text1}
                                file={card.videoLink}
                                height={140}
                                width={300}
                              />
                            )}
                            {card.image && (
                              <img
                                alt={card.text2}
                                key={card.text1}
                                src={card.image}
                                height={153}
                                width={360}
                              />
                            )}
                          </div>
                        </div>
                        <div className="dp-card-content-info">
                          <h3 className="dp-card-content-eyebrow">
                            {card.text1}
                          </h3>
                          <div className="dp-card-content-header">
                            <a className="dp-card-content-header-link">
                              <div
                                className={`dd-expand-right-small-2 ${
                                  card?.textColor || "text-black"
                                }`}
                              >
                                {card.text2}
                              </div>

                              <div
                                className={`dd-expand-right-small-2 ${
                                  card?.textColor || "text-black"
                                }`}
                              >
                                {card.text3}
                              </div>
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CardScrollerFeature;
